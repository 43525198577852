export const PerformanceLevels = {
	REDUCIDO: 1,
	PROMETEDORAS: 2,
	ELITES: 3
}

export const IndexLevels = {
	BAJO: 1,
	MEDIO: 2,
	ALTO: 3
}

export const BusinessAreas = {
	MINA: 1,
	CONEXA: 22,
}